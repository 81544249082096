import { render, staticRenderFns } from "./StudentsList.vue?vue&type=template&id=3ced8024&scoped=true&"
import script from "./StudentsList.vue?vue&type=script&lang=js&"
export * from "./StudentsList.vue?vue&type=script&lang=js&"
import style0 from "./StudentsList.vue?vue&type=style&index=0&id=3ced8024&lang=scss&scoped=true&"
import style1 from "./StudentsList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ced8024",
  null
  
)

export default component.exports